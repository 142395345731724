@import './_mixins.scss';

$base-font-size: 16;
$base-font-size-px: $base-font-size * 1px;
$base-unit: 8;

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');

$base-font-family: 'Roboto', sans-serif;

// takes in a unitless amount, and multiplies by $base-unit
@function bu($amount){
  @if unitless($amount) {
    @return ($base-unit*$amount/$base-font-size) * 1rem;
  }
  @else {
    @error "Base unit parameters must be unitless";
  }
}

$primary-color: (
  black:          #000000,
  dark-gray:      #504f4f,
  gray:           #808080,
  lp-dark-orange: #cc7000,
  lp-green:       #466b1c,
  lp-orange:      #ffbf00,
  lp-red:         #ff0000,
  white:          #FFFFFF
);

$border-radius: 5px;
$dark-box-shadow: 2px 2px 6px 0px rgba(8, 8, 8, 0.350);
$gray-border: 1px solid rgba(211, 211, 211, 0.500);
$standard-box-shadow: 1px 1px 7px 1px rgba(211, 211, 211, 0.705);
$text-shadow: 1px 1px 1px darken(map-get($primary-color, dark-gray), 20%);


.standard-page {
  header {
    img {
      width: bu(15);
    }
  }

  .header__nav ul .sub-nav {
    padding-top: bu(3.1);
  }
}

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 bu(2);

  img {
    width: bu(40);
    height: auto;
    margin-bottom: bu(4);
  }

  h3 {
    margin-bottom: bu(4);
    text-align: center;
  }

  ul {
    margin: 0;
    padding: 0;
    margin: bu(2) 0;
    list-style: none;
    text-align: center;

    li {
      &:not(:last-child) {
        margin-bottom: bu(1.5);
      }
    }

    a {
      text-decoration: none;
      font-weight: 400;
      color: map-get($primary-color, black);

      &:hover {
        color: map-get($primary-color, lp-orange);
      }
    }
  }
}
