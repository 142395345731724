@import '../../assets/scss/01_utilities/config';

.contact-form {
  width: 100%;

  &__form-group {
    display: flex;
    flex-direction: column;

    @include respond(medium) {
      flex-direction: row;
    }

    label {
      width: 100%;
      padding-top: bu(1.5);
      margin-bottom: bu(2);

      @include respond(medium) {
        width: 25%;
      }

      @include respond(large) {
        width: 20%;
      }
    }

    &__inputs {
      width: 100%;

      @include respond(medium) {
        width: 75%;
      }

      @include respond(large) {
        width: 80%;
      }
    }

    input, textarea {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border: none;
      box-shadow: $standard-box-shadow;
      font-size: bu(2);
      outline: none;
      padding: bu(1.5);
      width: 100%;
      border-radius: $border-radius;
      font-family: inherit;

      &:valid {
        border-bottom: 2px solid map-get($primary-color, lp-green);
      }

      &:invalid:focus {
        border-bottom: 2px solid map-get($primary-color, lp-red);
      }

      &:invalid:focus ~ .validation-error{
        opacity: 1;
        visibility: visible;
      }
    }

    .required-field {
      color: map-get($primary-color, lp-red);
      display: inline-block;
      font-size: bu(1.5);
      transform: translateY(-6px);
    }

    .validation-error {
      color: map-get($primary-color, lp-red);
      font-size: bu(1.4);
      margin: bu(1) 0 bu(2) 0;
      opacity: 0;
      text-align: right;
      visibility: hidden;
    }

  }
  .submit-error {
    color: map-get($primary-color, lp-red);
    font-weight: 400;
    margin: bu(2) 0;
  }

  input.submit {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: map-get($primary-color, lp-orange);
    border-radius: 5px;
    border: none;
    font-size: bu(1.7);
    max-width: bu(15);
    outline: none;
    padding: bu(1.5) bu(2.5);
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
    }
  }

  &__success {
    margin: 0 auto;
    max-width: bu(80);
    padding: bu(2) 0;

    h2 {
      border-bottom: none;
      margin-bottom: bu(1);
      padding-bottom: bu(1);
    }

    h3 {
      font-size: bu(3.1);
      margin-bottom: bu(1.5);
    }

    p {
      margin-bottom: bu(2);
    }

    &--highlight {
      color: map-get($primary-color, lp-orange);
    }
  }
}

.submit-details {
  border-bottom: 1px solid lighten(lightgray, 5%);
  border-top: 1px solid lighten(lightgray, 5%);
  margin-top: bu(4);
  padding: bu(3);

  &__group {
    &:not(:last-child) {
      margin-bottom: bu(4);
    }
  }
}
