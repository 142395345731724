@import './../../assets/scss/01_utilities/_config.scss';

.footer {
  padding: bu(2);
  background-color: map-get($primary-color, lp-orange);

  p {
    font-size: bu(1.5);
    color: map-get($primary-color, black);
    text-align: right;
  }
}
