@import './../../assets/scss/01_utilities/config';

.photo-gallery {
  column-gap: bu(3);
  display: grid;
  margin: bu(5) 0 bu(5) 0;

  &--weed-control {
    grid-template-columns: repeat(auto-fit, minmax(bu(40), 1fr));
    margin-bottom: bu(2);
  }

  &--past-work {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    margin-bottom: bu(2);

    @include respond(medium) {
      grid-template-columns: repeat(auto-fit, minmax(bu(60), 1fr));
    }

    figure {
      img {
        height: auto;
        max-width: 100%;
        object-fit: cover;

        @include respond(medium) {
          min-height: bu(50);
        }
      }
    }
  }

  figure {
    margin-bottom: bu(3);
    box-shadow: $standard-box-shadow;

    .author-cred {
      color: map-get($primary-color, gray);
      font-size: bu(1.1);
      padding: 0 0 bu(2) bu(1.7);

      a {
        color: map-get($primary-color, dark-gray);
        font-weight: 400;
        text-decoration: none;
      }
    }

    img {
      width: 100%;
      height: bu(35);
      object-fit: cover;
    }

    figcaption {
      font-size: bu(1.7);
      font-style: italic;
      font-weight: 400;
      padding: bu(1.5);
      background-color: white;
    }
  }

}
