@import '../../assets/scss/01_utilities/config';

.divider {
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: bu(10) 0;

  @include respond(large) {
    background-attachment: fixed;
  }

  h1 {
    color: map-get($primary-color, lp-orange);
    font-size: bu(6);
    font-weight: 400;
    text-shadow: $text-shadow;

    @include respond(medium) {
      font-size: bu(8);
    }
  }
}
