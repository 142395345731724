@import './../../assets/scss/01_utilities/_config.scss';

.header {
  background-color: map-get($primary-color, white);
  box-shadow: $standard-box-shadow;
  left: 0;
  padding: 0 bu(2);
  position: fixed;
  right: 0;
  top: 0;
  transition: .3s;
  width: 100%;
  z-index: 999;

  .button {
    border: 1px solid map-get($primary-color, black);
    font-size: bu(3);
    margin-top: bu(4);

    @include respond(medium) {
      border: none;
      font-size: bu(2);
      margin-top: 0;
    }
  }

  &__container {
    @include container;
    display: flex;
    justify-content: space-between;
    padding: bu(1) 0;
  }

  &__logo {
    cursor: pointer;

    img {
      height: auto;
      transition: .3s;
      width: bu(15);

      @include respond(large) {
        width: bu(28);
      }
    }

    &--scrolled {
      img {
        transition: .3s;
        width: bu(15);
      }
    }
  }

  &__nav {
    align-items: center;
    background-color: map-get($primary-color, lp-orange);
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    left: 0;
    opacity: 0;
    padding-top: bu(10);
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    width: 100%;
    transition: .3s;

    @include respond(medium) {
      background-color: map-get($primary-color, white);
      flex-direction: row;
      height: auto;
      justify-content: flex-start;
      opacity: 1;
      padding-top: 0;
      position: initial;
      visibility: visible;
    }

    ul {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      justify-content: center;
      list-style: none;
      text-align: center;

      @include respond(medium) {
        flex-direction: row;
        flex-grow: 2;
      }

      li {
        color: map-get($primary-color, black);
        font-size: bu(2.8);
        font-weight: 400;
        margin-bottom: bu(3);
        padding: 0 bu(3);
        position: relative;

        @include respond(medium) {
          font-size: bu(2);
          margin-bottom: 0;
        }
      }

      a {
        color: map-get($primary-color, black);
        font-weight: 400;
        text-decoration: none;
      }

      .sub-nav {
        padding-top: bu(3.1);

        @include respond(medium) {
          display: flex;
          flex-direction: column;
          left: -50%;
          margin-top: 0;
          padding-top: bu(3);
          position: absolute;
          transition: .3s;
          width: bu(30);
        }

        @include respond(large) {
          padding-top: bu(5.5);
        }

        li {
          @include respond(medium) {
            background-color: map-get($primary-color, lp-orange);
            padding: 0;

            &:not(:last-child) {
              border-bottom: 1px solid darken(map-get($primary-color, lp-orange), 3%);
            }

            &:hover {
              background-color: darken(map-get($primary-color, lp-orange), 4%);
            }
          }
        }

        a {
          display: block;
          font-size: bu(2);

          @include respond(medium) {
            padding: bu(2.5) bu(2);
          }
        }

        &--scrolled {

          @include respond(medium) {
            padding-top: bu(3.1);
            transition: .3s;
          }
        }
      }
    }

    &--active {
      opacity: 1;
      visibility: visible;
      transition: .3s;
    }
  }
}

.arrow-down {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid map-get($primary-color, black);
  height: 0;
  position: absolute;
  right: -25px;
  top: 10px;
  transition: .3s;
  width: 0;

  @include respond(medium) {
    border-top: 7px solid map-get($primary-color, lp-orange);
    right: -20px;
    top: 7px;
  }
}

.dropdown-wrapper {
  display: none;

  &--visible {
    display: block;
  }
}

.drop-down {
  cursor: pointer;
  position: relative;
}
