@import './../../../../../assets/scss/01_utilities/config';

.services-card {
  box-sizing: border-box;
  margin-bottom: bu(7);
  text-align: center;
  width: 100%;

  @include respond(medium) {
    padding: bu(3);
    width: 50%;
  }

  @include respond(large) {
    margin-bottom: 0;
    width: 25%;
  }

  .icon {
    height: bu(8);
    width: bu(8);
  }

  h2 {
    font-size: bu(3.5);
    margin: bu(3) 0;
    text-align: center;
    color: darken(map-get($primary-color, dark-gray), 20%);
  }

  p {
    font-size: bu(2.2);
    margin-bottom: bu(2);
  }
}
