@import '../../assets/scss/01_utilities/config';

.mobile-nav-trigger {
  height: 20px;
  position: relative;
  right: 10px;
  top: 10px;
  width: 30px;

  span {
    background-color: map-get($primary-color, black);
    display: block;
    height: 3px;
    transition: .3s;
    width: 100%;

    &:first-child {
      position: absolute;
      top: 0;
    }

    &:nth-child(2) {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }

    &:last-child {
      bottom: 0;
      position: absolute;
    }
  }

  &--active {
    span {
      background-color: map-get($primary-color, black);

      &:first-child {
        top: 8px;
        transform-origin: center;
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        top: 8px;
        transform-origin: center;
        transform: rotate(-135deg);
      }
    }
  }

  @include respond(medium) {
    display: none;
  }
}
