@import './../../assets/scss/01_utilities/config';

.page-intro {
  background-image: url('../../assets/images/gallery-9.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: bu(3);
  padding-bottom: bu(2);
  padding-top: bu(2);
  position: relative;

  @include respond(medium) {
    padding-top: bu(4);
    padding-bottom: bu(4);
  }

  h1 {
    color: map-get($primary-color, lp-orange);
    font-weight: 400;
    text-shadow: $text-shadow;

    @include respond(medium) {
      font-size: bu(9);
    }
  }
}
