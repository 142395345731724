@import '../../assets/scss/01_utilities/config';

.contact-overlay {
  background-image: url('../../assets//images/gallery-7.jpg');
  background-position: top 25% center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-page {
  @include container;
  padding: bu(15) bu(2) bu(6) bu(2);

  h1 {
    color: map-get($primary-color, white);
    font-size: bu(4);
    line-height: 1.2;
    margin-bottom: bu(5);
    text-align: left;
    text-shadow: $text-shadow;
    width: 100%;

    @include respond(medium) {
      font-size: bu(6);
      width: 80%;
    }

    @include respond(large) {
      width: 50%;
    }

    strong {
      color: map-get($primary-color, lp-orange);
    }
  }
}
