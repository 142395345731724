@import './../../../../assets/scss/01_utilities/config';

.hero-banner {
  align-items: center;
  background-attachment: scroll;
  background-image: url('./../../../../assets/images/gallery-2.jpg');
  background-position: top 50% center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: relative;

  &:after {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background-image:
    linear-gradient(
      to bottom right,
      rgba(5, 5, 5, 0.400) 20%, transparent 50%
    );
  }

  @include respond(large) {
    background-attachment: fixed;
  }

  .intro {
    @include container;
    display: flex;
    width: 100%;

    &__content {
      max-width: 100%;
      z-index: 99;

      h1 {
        color: map-get($primary-color, lp-orange);
        font-size: bu(6);
        font-weight: 400;
        margin-bottom: bu(4);
        margin-top: 15%;
        text-align: left;
        text-shadow: $text-shadow;

        @include respond(medium) {
          font-size: bu(9);
        }

        @include respond(large) {
          width: 75%;
        }
      }

      p {
        color: map-get($primary-color, white);
        font-size: bu(3.5);
        margin-bottom: bu(8);
        text-shadow: $text-shadow;

        @include respond(medium) {
          font-size: bu(5);
        }

        @include respond(large) {
          width: 75%;
        }
      }
    }
  }
}
