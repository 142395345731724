@import '../../assets/scss/01_utilities/config';

.button {
  display: inline-block;
  text-decoration: none;

  &--text {
    color: map-get($primary-color, dark-gray);
    font-weight: 400;
    margin: bu(2) 0;
    position: relative;

    &:after {
      backface-visibility: hidden;
      color: map-get($primary-color, lp-orange);
      content: '>';
      font-size: bu(3);
      position: absolute;
      transform: translateX(bu(1)) translateY(bu(-0.6)) scale(1);
      transition: .3s;
    }

    &:hover,
    &:focus {
      outline: none;

      &:after {
        transform: translateX(bu(2)) translateY(bu(-0.6)) scale(1.5);
        transition: .3s;
      }
    }
  }

  &--block {
    background-color: map-get($primary-color, lp-orange);
    border-radius: $border-radius;
    color: map-get($primary-color, black);
    font-size: bu(2.5);
    font-weight: 400;
    padding: bu(1.5) bu(2.5);
  }
}
