@import './../01_utilities/config';

h1 {
  font-size: bu(6);
  text-align: center;
  margin: bu(7) 0;
}

h2 {
  font-size: bu(5);
}

h3 {
  font-size: bu(4);
}

h4 {
  font-size: bu(3.5);
}

p {
  font-size: bu(2.3);
  line-height: 1.3;

  &:not(:last-child) {
    margin-bottom: bu(1.8);
  }
}

strong {
  font-weight: 400;
}
