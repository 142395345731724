@mixin container {
  max-width: bu(175);
  margin: 0 auto;
  padding: 0 bu(2);
}

@mixin respond($breakpoint) {
  @if $breakpoint == large {
    @media only screen and (min-width: 77.5em) {
      @content;
    }
  }

  @if $breakpoint == medium {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }
}
