@import './../../../../assets/scss/01_utilities/config';

.services {
  padding: bu(12) 0;

  &__container {
    @include container;
  }

  &__list {
    display: flex;
    flex-direction: column;

    @include respond(medium) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  h1 {
    color: map-get($primary-color, dark-gray);
    margin-top: 0;
    font-size: bu(6.5);
  }
}
