@import './../../assets/scss/01_utilities/config';

.service-heading {
  align-items: center;
  border-bottom: 2px solid map-get($primary-color, lp-orange);
  display: flex;
  flex-direction: column;
  margin-bottom: bu(4);
  padding-bottom: bu(2);

  @include respond(medium) {
    align-items: center;
    flex-direction: row;
  }

  &__icon {
    height: bu(7);
    order: 1;
    width: bu(7);

    @include respond(medium) {
      margin-left: bu(4);
      order: 2;
    }
  }

  h1 {
    margin-bottom: bu(2);
    margin-top: bu(2);
    order: 2;
    text-align: center;

    @include respond(medium) {
      order: 1;
      text-align: left;
    }
  }
}
