@import './../../../../assets/scss/01_utilities/config';

.about {
  padding: bu(12) 0;

  &__details {
    @include container;

    &-content {

      @include respond(medium) {
        flex-direction: row;
      }

      &__info {
        display: flex;
        flex-direction: column;

        @include respond(medium) {
          flex-direction: row;
        }

        &-content {

          width: 100%;

          &:first-child {

            @include respond(medium) {
              border-right: $gray-border;
            }
          }

          @include respond(medium) {
            padding-right: bu(6);
            width: 50%;
          }
        }

        &-content-right {
          align-self: center;
          border-top: $gray-border;
          margin-top: bu(4);
          padding-top: bu(7);
          width: 100%;

          @include respond(medium) {
            border-top: 0;
            margin-top: 0;
            padding-top: 0;
            width: 50%;
          }

          @include respond(large) {
            align-self: flex-start;
          }
        }

        .certified {
          align-items: center;
          display: flex;
          flex-direction: column;
          font-size: bu(3.3);
          text-align: center;
          color: map-get($primary-color, lp-orange);

          @include respond(medium) {
            padding: 0 bu(6);
          }

          img {
            height: auto;
            margin-bottom: bu(3);
            width: bu(8);
          }
        }

      }
    }

    h1 {
      color: map-get($primary-color, dark-gray);
      margin-top: 0;
      font-size: bu(6.5);
    }
  }
}
