@import './../../../assets/scss/01_utilities/config';

.services__page {
  @include container;
  display: flex;
  flex-direction: column;
  margin-top: bu(8);

  @include respond(large) {
    flex-direction: row;
  }

  aside {
    display: none;

    @include respond(large) {
      display: block;
      padding: bu(7) bu(3) bu(3) 0;
      position: fixed;
      width: bu(25);
    }

    a {
      border: 2px solid transparent;
      color: map-get($primary-color, black);
      display: inline-block;
      padding-bottom: bu(0.5);
      text-decoration: none;

      &:hover {
        border-bottom: 2px solid map-get($primary-color, lp-orange);
      }

      &:not(:last-child) {
        margin-bottom: bu(4);
      }

      &.active {
        border-bottom: 2px solid map-get($primary-color, lp-orange);
        font-weight: 400;
      }
    }
  }

  main {
    margin-left: 0;
    overflow-y: scroll;
    padding-top: bu(5);
    width: 100%;

    @include respond(large) {
      border-left: 1px solid rgba(211, 211, 211, 0.705);
      margin-left: bu(25);
    }

    .main-content {
      margin-bottom: bu(4);

      @include respond(large) {
        padding: 0 bu(6);
      }
    }

    h2 {
      font-size: bu(4);
      margin-bottom: bu(3);
    }

    hr {
      height: 1px;
      border: 0;
      outline: none;
      background-color: rgba(211, 211, 211, 0.705);
      margin-bottom: bu(4);
    }

    ol {
      font-size: bu(2.3);
      margin-bottom: bu(3.5);
      margin-left: bu(4);
      margin-top: bu(3.5);
      padding: 0;

      li {
        &:not(:last-child) {
          margin-bottom: bu(2);
        }
      }
    }
  }

  .contact-banner__content {
    @include respond(large) {
      padding-left: bu(6);
      padding-right: bu(6);
    }
  }

  .fertiliser-img {
    background-image: url('../../../assets/images/fertilizer-spreader.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 55vh;
    margin-top: bu(3);
  }
}
