@import '../../assets//scss/01_utilities/config';

.full-page-img {
  height: bu(30);
  margin: bu(4) 0;
  overflow: hidden;

  @include respond(medium) {
    height: bu(45);
  }

  img {
    height: auto;
    max-width: 100%;
  }
}
