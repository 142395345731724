@import '../../assets/scss/01_utilities/config';

.contact-banner {
  background-color: rgba(211, 211, 211, 0.150);
  border-top: $gray-border;

  &__content {
    @include container;
    display: flex;
    flex-direction: column;
    padding-bottom: bu(10);
    padding-top: bu(10);
    width: 100%;

    @include respond(medium) {
      flex-direction: row;
    }

    &-info {
      width: 100%;

      p {
        text-align: center;

        @include respond(medium) {
          text-align: left;
        }
      }

      &:first-child {
        @include respond(medium) {
          padding-right: bu(5);
        }
      }

      @include respond(medium) {
        width: 40%;
      }


      &.form {
        border-top: 1px solid rgba(211, 211, 211, 0.200);
        margin-top: bu(2);
        padding-top: bu(5);

        @include respond(medium) {
          border-top: none;
          margin-top: 0;
          padding-top: 0;
          width: 60%;
        }
      }

      img {
        height: auto;
        max-width: bu(30);
      }

      .logo {
        display: none;
        margin-top: bu(9);

        @include respond(medium) {
          display: block;
        }
      }

      h2 {
        border-bottom: 1px solid rgba(211, 211, 211, 0.500);
        color: map-get($primary-color, dark-gray);
        font-size: bu(3.5);
        line-height: 1.2;
        margin-bottom: bu(5);
        padding-bottom: bu(5);
        width: 100%;

        @include respond(medium) {
          font-size: bu(3);
          width: 80%;
        }

        @include respond(large) {
          font-size: bu(4);
        }
      }

      a {
        align-items: center;
        color: map-get($primary-color, black);
        display: inline-flex;
        text-decoration: none;

        &:hover,
        &:focus {
          color: map-get($primary-color, lp-orange);
          outline: none;
        }

        img {
          height: auto;
          margin-right: bu(2);
          width: bu(2.3);
        }
      }
    }
  }
}
