@import './../../assets/scss/01_utilities/config';

.about-page {
  margin-top: 66px;

  &__content {
    @include container;
    display: flex;
    flex-direction: column;
    padding-bottom: bu(4) !important;
    padding: bu(5) bu(2);

    @include respond(medium) {
      flex-direction: row;
      padding: bu(12) bu(2);
    }

    &-text {
      border-bottom: $gray-border;
      padding-bottom: bu(5);
      width: 100%;

      @include respond(medium) {
        border-bottom: none;
        border-right: $gray-border;
        padding-right: bu(5);
        width: 50%;
      }

      h3 {
        line-height: 1.2;
        margin-bottom: bu(5);

        strong {
          color: map-get($primary-color, lp-orange);
        }
      }
    }

    &-highlights {
      padding-top: bu(5);
      width: 100%;

      @include respond(medium) {
        padding-left: bu(5);
        padding-top: 0;
        width: 50%;
      }
    }
  }
}

.about-page__content-highlights {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include respond(medium) {
    flex-direction: row;
  }
}

.about-highlights {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: bu(4);
  text-align: center;
  width: 100%;

  @include respond(medium) {
    justify-content: center;
    width: 100%;
  }

  @include respond(large) {
    justify-content: flex-start;
    width: 50%;
  }

  p {
    color: map-get($primary-color, gray);
    font-size: bu(2.3);

    @include respond(large) {
      font-size: bu(2.5);
    }

    a {
      color: map-get($primary-color, lp-orange);
      display: block;
      font-weight: 400;
      text-decoration: none;
    }
  }

  img {
    height: bu(7);
    margin-bottom: bu(3);
    width: bu(7);
  }
}
